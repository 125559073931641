import UcraftLogo from '/public/assets/images/svg/ucraftLogo.svg';
import Vector from '/public/assets/images/svg/vector.svg';
import Play from '/public/assets/images/svg/play.svg';
import Pause from '/public/assets/images/svg/pause.svg';
import Search from '/public/assets/images/svg/search.svg';
import Close from '/public/assets/images/svg/close.svg';
import EmptyState from '/public/assets/images/svg/emptyState.svg';
import ArrowDown from '/public/assets/images/svg/arrowDown.svg';
import ArrowUp from '/public/assets/images/svg/arrowUp.svg';
import Star from '/public/assets/images/svg/star.svg';
import ArrowLeft from '/public/assets/images/svg/arrowLeft.svg';
import VisaCards from '/public/assets/images/svg/visaCards.svg';
import PoweredByStripe from '/public/assets/images/svg/poweredByStripe.svg';
import Installation from '/public/assets/images/svg/installation.svg';
import CloseOutline from '/public/assets/images/svg/closeOutline.svg';
import EmptyProjectsState from '/public/assets/images/svg/emptyProjectsState.svg';
import ArrowRight from '/public/assets/images/svg/arrowRight.svg';
import BackArrowLeft from '/public/assets/images/svg/backArrowLeft.svg';
import MobileLoginIcon from '/public/assets/images/svg/mobileLoginIcon.svg';
import LikeIcon from '/public/assets/images/svg/like.svg';
import Unlike from '/public/assets/images/svg/unlike.svg';
import Edit from '/public/assets/images/svg/edit.svg';
import EmptyReview from '/public/assets/images/svg/emptyReview.svg';
import Place from '/public/assets/images/svg/place.svg';
import DisabledLike from '/public/assets/images/svg/disbaledLike.svg';
import DisabledUnLike from '/public/assets/images/svg/disbaledUnlike.svg';
import FacebookFilled from '/public/assets/images/svg/facebookFilled.svg';
import TwitterSquareFilled from '/public/assets/images/svg/twitterSquareFilled.svg';
import InstagramFilled from '/public/assets/images/svg/InstagramFilled.svg';
import YoutubeFilled from '/public/assets/images/svg/YoutubeFilled.svg';
import PinterestIcon from '/public/assets/images/svg/PinterestIcon.svg';
import LinkedinFilled from '/public/assets/images/svg/LinkedinFilled.svg';
import VimeoIcon from '/public/assets/images/svg/VimeoIcon.svg';
import Emoji from '/public/assets/images/svg/emojiSmail.svg';
import Dots from '/public/assets/images/svg/dots.svg';
import notSelectedEmoji from '/public/assets/images/svg/notSelectedEmoji.svg';
import bulletPoint from '/public/assets/images/svg/bulletPoint.svg';
import setupGuide from '/public/assets/images/svg/setupGuide.svg';
import arrowDownBenefit from '/public/assets/images/svg/arrowDownBenefit.svg';
import arrowUpBenefit from '/public/assets/images/svg/arrowUpBenefit.svg';
import advertising from '/public/assets/images/svg/advertising.svg';
import analytics from '/public/assets/images/svg/analytics.svg';
import contactTheUser from '/public/assets/images/svg/contactTheUser.svg';
import ownerIcon from '/public/assets/images/svg/ownerIcon.svg';
import privacyPolicyMainIcon from '/public/assets/images/svg/privacyPolicyMainIcon.svg';
import thirdPartyAccounts from '/public/assets/images/svg/thirdPartyAccounts.svg';
import registration from '/public/assets/images/svg/registration.svg';
import appStoreNewLogo from '/public/assets/images/svg/newAppStoreLogo.svg';
import projectResetIcon from '/public/assets/images/svg/projectResetIcon.svg';
import appStoreMobileLogo from '/public/assets/images/svg/appStoreMobileLogo.svg';

import { IconType } from './types';

export const iconTypes: IconType = {
  ucLogo: UcraftLogo,
  vector: Vector,
  play: Play,
  pause: Pause,
  search: Search,
  close: Close,
  star: Star,
  emptyState: EmptyState,
  installation: Installation,
  closeOutline: CloseOutline,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  visaCards: VisaCards,
  poweredByStripe: PoweredByStripe,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  emptyProjectsState: EmptyProjectsState,
  backArrowLeft: BackArrowLeft,
  facebookFilled: FacebookFilled,
  twitterSquareFilled: TwitterSquareFilled,
  instagramFilled: InstagramFilled,
  youtubeFilled: YoutubeFilled,
  pinterestIcon: PinterestIcon,
  linkedinFilled: LinkedinFilled,
  vimeoIcon: VimeoIcon,
  mobileLoginIcon: MobileLoginIcon,
  like: LikeIcon,
  unlike: Unlike,
  edit: Edit,
  emptyReview: EmptyReview,
  place: Place,
  disabledLike: DisabledLike,
  disabledUnlike: DisabledUnLike,
  emoji: Emoji,
  dots: Dots,
  notSelectedEmoji: notSelectedEmoji,
  bulletPoint: bulletPoint,
  setupGuide: setupGuide,
  arrowDownBenefit: arrowDownBenefit,
  arrowUpBenefit: arrowUpBenefit,
  advertising: advertising,
  analytics: analytics,
  contactTheUser: contactTheUser,
  ownerIcon: ownerIcon,
  privacyPolicyMainIcon: privacyPolicyMainIcon,
  registration: registration,
  thirdPartyAccounts: thirdPartyAccounts,
  appStoreNewLogo: appStoreNewLogo,
  projectResetIcon: projectResetIcon,
  appStoreMobileLogo: appStoreMobileLogo
};
